
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import { IParceiro } from '@/models/Entidades/IParceiro';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import ServicoApp from '@/servicos/ServicoApp';

export default defineComponent({
  name: 'Transacoes',
  components: {
    ComunicacaoApi,
  },
  setup() {
    const {
      appBase,
    } = useAppBase();
    const servicoApp = new ServicoApp();
    const state = reactive({
      parceiro: {} as IParceiro,
      cep: '',
      cpfCnpj: '',
    });

    function limparDados() {
      state.parceiro = {} as IParceiro;
      state.parceiro.codigo = 0;
      state.parceiro.codigoCidade = 0;
      state.parceiro.whatsapp = '';
      state.parceiro.whatsappMensagem = '';
      state.cpfCnpj = '';
      state.cep = '';
    }

    onBeforeMount(async () => {
      limparDados();
      appBase.carregando = true;
      state.parceiro = await servicoApp.obterDadosMeuNegocio();
      state.cpfCnpj = state.parceiro.cpfCnpj;
      state.cep = state.parceiro.cep;
      appBase.carregando = false;
    });

    return {
      appBase,
      state,
    };
  },
});
